import i18n from 'i18next';
import 'moment/locale/nl';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationNL from './locales/nl.json';

const resources = {
  en: translationEN,
  nl: translationNL
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
