import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getI18n } from 'react-i18next';
import { request } from './actions';
import Loading from './components/loading';
import moment from 'moment';

export const AuthContext = createContext({ authToken: '', user: {} });

const AuthContextProvider = ({ children, authToken = '', initialUser = null, ...props }) => {
  const sessionToken =
    props.authToken ||
    document.cookie
      .split(';')
      .find((c) => c.includes('_gk_session='))
      .trim()
      .replace(/_gk_session=/, '');
  const [currentUser, setCurrentUser] = useState(initialUser);

  useEffect(() => {
    if (currentUser !== null || sessionToken === '') return;

    (async () => {
      const query = `{
        currentUser { id firstName lastName locale md5Hash }
      }`;
      const options = {
        body: JSON.stringify({ query }),
        method: 'POST',
      };
      const [response] = await request('/graphql', sessionToken, options);
      getI18n().changeLanguage(response.data.currentUser.locale);
      moment.locale(response.data.currentUser.locale);
      setCurrentUser(response.data.currentUser);
    })();
  }, [sessionToken, currentUser]);

  if (currentUser === null) {
    return <Loading />;
  }
  return <AuthContext.Provider value={{ authToken: sessionToken, user: currentUser }}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
  authToken: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  user: PropTypes.object,
  initialUser: PropTypes.object,
};

export default AuthContextProvider;
