import React from 'react';
import * as Sentry from '@sentry/browser';
import './i18n';
import './index.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

window.AvailableLocales = ['nl', 'en'];

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://66680e198f4c48c1b372bb6314a566b3@sentry.io/5175532' });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
