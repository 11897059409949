import React from 'react';
import Loading from '../loading';

const Gatekeeper = () => {
  const params = {};
  window.location.search.substring(1).split('&').forEach(param => {
    const keyValue = param.split('=');
    params[keyValue.shift()] = keyValue.join('=');
  });
  const authToken = params.message;

  if (authToken === undefined) {
    window.location = `${process.env.REACT_APP_GATEKEEPER_URL}/session?host=${window.location.host}&return_url=${window.location.pathname}`;
    return(<div>Redirecting...</div>);
  } else {
    document.cookie = `_gk_session=${decodeURIComponent(authToken)};path=/;secure;domain=${window.location.host}`;
    window.location = params.return_url || '/';

    return(
      <Loading />
    );
  }
};

export default Gatekeeper;
