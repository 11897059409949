import * as Sentry from '@sentry/browser';

const url = `${process.env.REACT_APP_BACKEND_URL}/graphql`;
const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const request = (path, ...args) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}${path}`;
  return send(url, ...args);
};

const send = async (url, authToken, options = {}, headers = defaultHeaders) => {
  options = {
    method: 'POST',
    ...options,
    headers: {
      ...headers,
      Authorization: authToken,
    },
  };

  try {
    const response = await fetch(url, options);
    const json = await response.json();
    return [json, null];
  } catch (error) {
    Sentry.setExtra('error', error);
    console.error(error);
    return [null, error];
  }
};

export const graphqlRequest = async (query, variables, authToken, option = {}) => {
  const options = {
    body: JSON.stringify({ query, variables, locale: option.locale }),
    headers: {
      Accept: 'application/json',
      Authorization: authToken,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    signal: option.controller !== undefined ? option.controller.signal : undefined,
  };

  try {
    const response = await fetch(url, options);

    if (response.status === 200) {
      const json = await response.json();

      if (json.errors === undefined) {
        return [json.data, null];
      } else {
        Sentry.setExtra('error', json.error);
        console.error(json.errors);
        throw new Error(json.errors);
      }
    } else {
      return [null, response];
    }
  } catch (error) {
    return [null, error];
  }
};
