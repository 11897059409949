import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLandmark, faNewspaper, faUsers } from '@fortawesome/pro-solid-svg-icons';

const MenuItems = ({ className = null }) => {
  const { t } = useTranslation('navbar');
  className = ['nav-item', className].join(' ');

  return (
    <li className={className}>
      <NavLink to="/organisations" className={({ isActive }) => `${isActive ? 'active' : 'none'} nav-link`}>
        <FontAwesomeIcon icon={faLandmark} /> <span>{t('organisations')}</span>
      </NavLink>
      <NavLink to="/product-news" className="nav-link">
        <FontAwesomeIcon icon={faNewspaper} /> <span>{t('productNews')}</span>
      </NavLink>
      <NavLink to="/users" className="nav-link">
        <FontAwesomeIcon icon={faUsers} /> <span>{t('users')}</span>
      </NavLink>
    </li>
  );
};

MenuItems.propTypes = {
  className: PropTypes.string,
};

export default MenuItems;
