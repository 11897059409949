import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import AuthContextProvider from '../../auth-context';

import Gatekeeper from '../gatekeeper';
import Loading from '../loading';
import Navbar from '../navbar';
import NavItems from '../navbar/nav-items';

import './app.scss';

const EditOrganisation = lazy(() => import('../../containers/edit-organisation'));
const ManualChapter = lazy(() => import('../../containers/manual-chapter'));
const ManualChapters = lazy(() => import('../../containers/manual-chapters'));
const ManualParagraph = lazy(() => import('../../containers/manual-paragraph'));
const Members = lazy(() => import('../../containers/members'));
const NewManual = lazy(() => import('../../containers/new-manual'));
const NewOrganisation = lazy(() => import('../../containers/new-organisation'));
const Organisation = lazy(() => import('../../containers/organisation'));
const Organisations = lazy(() => import('../../containers/organisations'));
const Users = lazy(() => import('../../containers/users'));
const User = lazy(() => import('../../containers/user'));
const NewProductNews = lazy(() => import('../../containers/new-product-news'));
const ProductNews = lazy(() => import('../../containers/product-news'));
const EditProductNewsItem = lazy(() => import('../../containers/edit-product-news-item'));
const RegistrationActiveUsers = lazy(() => import('../../containers/registration-active-users'));
const TextBlock = lazy(() => import('../../containers/text-block'));

const App = () => {
  const authenticated = document.cookie.split(';').some((c) => c.includes('_gk_session='));

  if (!authenticated) {
    return (
      <div className="container-fluid">
        <Gatekeeper />
      </div>
    );
  }

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <AuthContextProvider>
          <Navbar />
          <div className="container-fluid">
            <div className="row main-container">
              <div className="col-1 sidebar sidebar__navbar d-none d-lg-block">
                <ul className="sidebar__navbar__menu">
                  <NavItems />
                </ul>
              </div>
              <div className="col main-content">
                <Routes>
                  <Route exact path="/" element={<Organisations />} />
                  <Route exact path="/organisations" element={<Organisations />} />
                  <Route exact path="/product-news" element={<ProductNews />} />
                  <Route exact path="/users" element={<Users />} />
                  <Route exact path="/users/:id" element={<User />} />
                  <Route exact path="/product-news/new" element={<NewProductNews />} />
                  <Route exact path="/product-news/:id/edit" element={<EditProductNewsItem />} />
                  <Route exact path="/organisations/new" element={<NewOrganisation />} />
                  <Route exact path="/organisations/:id" element={<Organisation />} />
                  <Route exact path="/organisations/:id/edit" element={<EditOrganisation />} />
                  <Route exact path="/organisations/:organisationId/manuals/new" element={<NewManual />} />
                  <Route
                    exact
                    path="/organisations/:organisationId/manual-chapters/:manualChapterId"
                    element={<ManualChapter />}
                  />
                  <Route exact path="/organisations/:organisationId/manual-chapters" element={<ManualChapters />} />
                  <Route
                    exact
                    path="/organisations/:organisationId/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId"
                    element={<ManualParagraph />}
                  />
                  <Route
                    exact
                    path="/organisations/:organisationId/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/text-blocks/:textBlockId"
                    element={<TextBlock />}
                  />
                  <Route exact path="/organisations/:organisationId/members" element={<Members />} />
                  <Route
                    exact
                    path="/organisations/:organisationId/registration-active-users"
                    element={<RegistrationActiveUsers />}
                  />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            </div>
          </div>
        </AuthContextProvider>
      </Suspense>
    </Router>
  );
};

export default App;
